import "./Footer.css"
import { Link } from "react-router-dom"
import { FaInstagram,FaFacebookF, FaTiktok } from "react-icons/fa";
import { HiOutlinePhone } from "react-icons/hi";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const {t} = useTranslation()
  return (
    <footer className="footer_c container">
      <div className="footerSection flex-h justify-content-between align-items-center">
        <div className="footerLogo">HORIZON DEKORASYON</div>
        <div className="footerMail">info@horizons-d.com</div>
        <div className="footerShowBtn_c">
          <a className="btn d-btn" target={"blank"} href="https://maps.app.goo.gl/RxK2jxXvmwmgiGWu9">
            {t("showLocation")}
          </a>
        </div>
      </div>
      <div className="footerSection flex-h justify-content-between align-items-center">
        <div className="footerLinks flex-h">
          <Link to={"/"} className="footerLink underline_link">{t("navLink_home")}</Link>
          <a href="/services" className="footerLink underline_link">{t("navLink_services")}</a>
          <a href="/projects" className="footerLink underline_link">{t("navLink_projects")}</a>
        </div>
        <div className="footerSocial">
          <Link target="_blank" to={"https://www.instagram.com/horizon.dekorasyon"}><FaInstagram size={25} /></Link>
          <Link target="_blank" to={"https://www.facebook.com/Horizondekorasyon/"}><FaFacebookF size={25} /></Link>
          <Link target="_blank" to={"https://www.tiktok.com/@horizon_dekorasyon"}><FaTiktok size={25} /></Link>
        </div>
        <div className="footerPhone_c">
          <a href="tel:+90 537 738 73 99" className="footerPhone flex-h"><HiOutlinePhone size={25} />+90 537 738 73 99</a>
        </div>
      </div>
      <hr />
      <div className="footerSection flex-h justify-content-between align-items-center">
        <div className="footerLink">www.horizons-d.com</div>
        <div className="footerCopyRight">Horizon Dekorasyon &copy; Copyright {new Date().getFullYear()}</div>
        <div className="footerLocation">Istanbul / Başakşehir</div>
      </div>
    </footer>

  )
}

export default Footer