import projectsData from "../../Data/ProjectsData";
import "./Projects.css"
import { SectionHero } from "../../Components/index";
import { Footer, Nav } from "../../Components/index"
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet"



const Projects = () => {
  const [file, setFile] = useState()
  const popUpImg = useRef();
  const t = useTranslation().t
  const getProjectsData = projectsData.map((data, i) => {
    return <div key={i} className="imagesBox" onClick={() => { setFile(data.img) }}>
      <img src={data.img} alt="" />
    </div>
  })
  useEffect(() => {
    if (popUpImg.current) {
      if (popUpImg.current.naturalHeight > popUpImg.current.naturalWidth) {
        popUpImg.current.classList.add("aspect-v")
        popUpImg.current.classList.remove("aspect-h")
      }
      else {
        popUpImg.current.classList.remove("aspect-v")
        popUpImg.current.classList.add("aspect-h")
      }
    }
  }, [file])


  return (
    <>
      <Helmet>
        <title>{t("navLink_projects")}</title>
        <meta name="description" content={t("metaProjectsDesc")} />
        <meta property="og:description" content={t("metaProjectsDesc")} />
      </Helmet>
      <Nav />
      <SectionHero>
        <h1 className='sectionHeroTitle'>
          {t("ourProjects")}
        </h1>
      </SectionHero>
      <div className="container projects_c images_c flex-h">
        {
          getProjectsData
        }
      </div>
      <div className="popup-media" style={{ display: file ? "block" : "none" }}>
        <div className="popupBg"></div>
        <span className="popup-close" onClick={() => { setFile(null) }}>&times;</span>
        <img ref={popUpImg} src={file ? file : ""} alt="" />
      </div>
      <Footer />
    </>
  )
}

export default Projects