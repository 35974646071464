import './AboutUs.css'
import AboutImg_1 from "../../assets/images/about_1.png"
import AboutImg_2 from "../../assets/images/about_2.png"
import { useTranslation } from 'react-i18next'

const AboutUs = () => {
  const { t } = useTranslation()
  const { i18n } = useTranslation()
  const isRtl = i18n.language === "ar"
  const aboutTextStyle = {
    direction: isRtl ? 'rtl' : 'ltr',
    textAlign: isRtl ? 'right' : 'left',
  }
  const getAboutText = t("aboutText", { returnObjects: true }).map((item,i)=>{
    return <li className='aboutTextItem'>
                {t("aboutText", { returnObjects: true })[i]}
           </li>;
  })
  
  return (
    <>
      <div className='container aboutUs-c'>
        <div className='aboutUsImgs'>
          <img className='aboutUsImg aboutUsImg_1' src={AboutImg_1} alt="" />
          <img className='aboutUsImg aboutUsImg_2' src={AboutImg_2} alt="" />
        </div>
        <div className='aboutUsTexts' style={aboutTextStyle}>
          <p className='aboutSub'>{t("aboutSub")}</p>
          <p className='aboutTitle'>{t("aboutTitle", { returnObjects: true }).line_1}</p>
          <p className={`aboutCoute ${isRtl ? "borderRight" : "borderLeft"}`}>{t("aboutCoute")}</p>
          <ul className='aboutText'>
            {getAboutText}
          </ul>
        </div>
      </div>
    </>
  )
}

export default AboutUs