import "./Catalogues.css"
import { Footer, Nav, SectionHero, CataloguesItem } from "../../Components"
import { useTranslation } from "react-i18next"
import CataloguesData from "../../Data/CataloguesData";
import { Helmet } from "react-helmet"


function Catalogues() {
    const t = useTranslation().t;
    const getCatalogueData = CataloguesData.map((data, i) => {
        return <CataloguesItem img={data.img} title={data.title} catalogue={data.catalogue} />
    })
    return (
        <>
            <Helmet>
                <title>{t("catalogues")}</title>
                <meta name="description" content={t("metacataloguesDesc")} />
                <meta property="og:description" content={t("metacataloguesDesc")} />
            </Helmet>
            <Nav />
            <SectionHero>
                <h1 className='sectionHeroTitle'>
                    {t("catalogues")}
                </h1>
            </SectionHero>
            <div className="container catalogues-c">
                {getCatalogueData}
            </div>
            <Footer />
        </>
    )
}

export default Catalogues