import { Hero, AboutUs, MainCategories, BeforeAfter, Companies } from "../../Sections/index"
import { Footer, Nav } from "../../Components/index"
import { Helmet } from "react-helmet"
import { useTranslation } from 'react-i18next'

const Home = () => {
    const {t} = useTranslation()
    return (
        <>
            <Helmet>
                <title>{t("homepage")}</title>
                <meta name="description" content={t("metaDesc")} />
                <meta property="og:description" content={t("metaDesc")}></meta>
            </Helmet>
            <Nav />
            <Hero />
            <AboutUs />
            <MainCategories />
            <BeforeAfter />
            <Companies />
            <Footer />
        </>
    )
}

export default Home